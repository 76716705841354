<template>
  <v-dialog
      v-model="dialog"
      width="1000"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs"
             v-on="on" color="blue">
        <v-icon>mdi-link</v-icon>
        MPU Fall verknüpfen
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="primary">
        MPU Fall verknüpfen
      </v-card-title>
      <v-card-text>
        <v-autocomplete
            v-model="filter"
            :items="cases"
            :search-input.sync="searchCase"
            class="pa-0"
            color="primary"
            hide-no-data
            hide-selected
            item-text="customerLastname"
            item-value="id"
            placeholder="Fall suchen"
            return-object
        ></v-autocomplete>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="error"
            @click="dialog = false"
        >
          Abbrechen
        </v-btn>
        <v-btn
            color="blue"
            @click="link()"
        >
          Absenden
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {getMpuURL, requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";

export default {
  name: "LinkEventWithCase",
  props: {
    event: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    dialog: false,
    case: undefined,
    filter: undefined,
    searchCase: null,
    cases: [],
  }),
  methods: {
    async link() {
      //  if Session isValid
      await sessionHandler();

      this.$http
          .patch(
              getMpuURL() + "/" + this.filter.id + "/Appointment/" + this.event.id
              , {}, {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            // TODO error if appointment is already full of linked cases
            if (response.status === 200) {
              this.dialog = false;
            } else {
              showAlert(i18n.t('error.api.undefined') + "LinkEventWithCase-gOC1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "LinkEventWithCase-gOC2", "error");
            }
          })
          .finally(() => {
            this.isLoadingCases = false;
          })
    },
    async getOpenCases() {
      //  if Session isValid
      await sessionHandler();

      this.$http
          .get(
              getMpuURL() + "?isClosed=false"
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.cases = response.data.filter((item) => {
                return item.numberOfAssignedAppointments  !== item.numberOfBookedAppointments
              });
            } else {
              showAlert(i18n.t('error.api.undefined') + "LinkEventWithCase-gOC1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "LinkEventWithCase-gOC2", "error");
            }
          })
          .finally(() => {
            this.isLoadingCases = false;
          })
    }
  },
  mounted() {
    this.getOpenCases();
  },
  watch: {
    searchCreator() {
      // Items have already been loaded
      if (this.cases.length > 0) return

      // Items have already been requested
      if (this.isLoadingCases) return

      this.isLoadingCases = true

      // Lazily load input items
      this.getOpenCases();

    },
  }
}
</script>

<style scoped>

</style>
