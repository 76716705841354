import {sessionHandler} from "@/request/sessionHandler";
import {getAppointmentsURL, requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";
import Vue from 'vue';
import reactiveStorage from "@/plugins/reactiveStorage";

export class HistoryHandler {
    history = [];
    oppositeTypes = {create: "delete", delete: "create", update: "update"};
    currentIndex = 0;

    add(historyEntity){
        this.history.push(historyEntity);
        this.currentIndex = this.history.length - 1
    }

    getURL(){
        if (this.history[this.currentIndex].url === getAppointmentsURL()){
            return this.history[this.currentIndex].url
        } else {
            return this.history[this.currentIndex].url + "/" + this.history[this.currentIndex].dataAfter.id
        }
    }
    getConfig(){
        if (this.history[this.currentIndex].url === getAppointmentsURL()){
            return {
                data: this.history[this.currentIndex].url === getAppointmentsURL() ? this.history[this.currentIndex].dataAfter : {},
                mode: requestMode(),
                headers: {
                    'Accept': 'application/json',
                    'authorization': reactiveStorage.app.AuthenticationType + ' ' + Vue.$cookies.get('access_token'),
                }
            }
        } else {
            return {
                mode: requestMode(),
                headers: {
                    'Accept': 'application/json',
                    'authorization': reactiveStorage.app.AuthenticationType + ' ' + Vue.$cookies.get('access_token'),
                }
            }
        }
    }
    oldIDFinder(newID){
        let problemHistory = history.filter(x => x.id === this.history[this.currentIndex].dataBefore.id);
        let normalHistory = history.filter(x => x.id !== this.history[this.currentIndex].dataBefore.id);
        problemHistory.forEach(historyEntity => {
            historyEntity.id = newID;
        })
        this.history = normalHistory.concat(problemHistory)
        // console.log("oldFinder",  this.history)
    }

    async sendRequests(){
        // console.log(this.history)
        // console.log(this.history[this.currentIndex])
        switch (this.oppositeTypes[this.history[this.currentIndex].type]){
            case "create":
                // eslint-disable-next-line no-case-declarations
                const createFormData = {
                    "allDay": this.history[this.currentIndex].dataBefore.allDay,
                    "start": this.history[this.currentIndex].dataBefore.start,
                    "end": this.history[this.currentIndex].dataBefore.end,
                    "name": this.history[this.currentIndex].dataBefore.name,
                    "appointmentType": this.history[this.currentIndex].dataBefore.appointmentType,
                };
                await sessionHandler();

                await Vue.axios
                    .post(
                        this.history[this.currentIndex].url,
                        createFormData
                        , {
                            mode: requestMode(),
                            headers: {
                                'Accept': 'application/json',
                                'authorization': reactiveStorage.app.AuthenticationType + ' ' + Vue.$cookies.get('access_token'),
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.oldIDFinder(this.history[this.currentIndex].dataBefore.id)
                            showAlert(i18n.t('success.form'), "success");
                        } else {
                            showAlert(i18n.t('error.api.undefined') + "Calendar-hH1", "error");
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                showAlert(i18n.t('warning.unauthorised'), "warning");
                            }
                        } else {
                            showAlert(i18n.t('error.api.undefined') + "Calendar-hH1", "error");
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    })

                break;
            case "delete":
                await sessionHandler();

                await Vue.axios
                    .delete(
                        this.getURL(),
                        this.getConfig()
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            showAlert(i18n.t('success.form'), "success");
                        } else {
                            showAlert(i18n.t('error.api.undefined') + "Calendar-hH1", "error");
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                showAlert(i18n.t('warning.unauthorised'), "warning");
                            }
                        } else {
                            showAlert(i18n.t('error.api.undefined') + "Calendar-hH1", "error");
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    })
                break;
            case "update":
                // eslint-disable-next-line no-case-declarations
                const updateFormData = {
                    "allDay": this.history[this.currentIndex].dataAfter.allDay,
                    "start": this.history[this.currentIndex].dataBefore.start,
                    "end": this.history[this.currentIndex].dataBefore.end,
                    "name": this.history[this.currentIndex].dataBefore.name,
                    "appointmentType": this.history[this.currentIndex].dataAfter.appointmentType,
                };
                await sessionHandler();

                await Vue.axios
                    .put(
                        this.getURL(),
                        updateFormData
                        , {
                            mode: requestMode(),
                            headers: {
                                'Accept': 'application/json',
                                'authorization': reactiveStorage.app.AuthenticationType + ' ' + Vue.$cookies.get('access_token'),
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            showAlert(i18n.t('success.form'), "success");
                        } else if(response.status === 404) {
                            // history handler wollte einen Termin updaten, der aber nicht mehr existiert, da er vorher gelöscht wurde
                            // es müsste also erst der Termin wieder erstellt werden und dann mit neuer ID upgedatet werden
                            // .log("Erst wieder erstellen dann wieder versuchen xD")
                        } else {
                            showAlert(i18n.t('error.api.undefined') + "Calendar-hH1", "error");
                        }
                    })
                    .catch((error) => {
                        if (!error.response) {
                            showAlert(i18n.t('error.api.undefined') + "Calendar-hH2", "error");
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    })
                break;
        }
    }

    async back(){
        await this.sendRequests();
        this.currentIndex = this.currentIndex - 1;
    }
    async forward(){
        await this.sendRequests();
        this.currentIndex = this.currentIndex + 1;
    }
}

export class HistoryEntity{

    url = "";
    type = "";
    dataBefore = {};
    dataAfter = {};
    constructor(url, type, dataAfter,dataBefore){
        this.url = url
        this.type = type;
        this.dataAfter = dataAfter;
        if (dataBefore){
            this.dataBefore = dataBefore;
        }
    }
}
