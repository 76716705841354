<template>
  <div>
    <v-card>
      <v-card-title
          class="headline primary"
          primary-title
      >
        <v-btn icon style="margin-right: 15px" text @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        Wochen
        <v-toolbar style="margin-top: 3vh">
          <v-row style="margin: 15px 0 0 0">
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-autocomplete
                  v-model="filter"
                  :items="creators"
                  :search-input.sync="searchCreator"
                  clearable
                  @click:clear="clearLawyer()"
                  color="primary"
                  hide-no-data
                  hide-selected
                  item-text="username"
                  item-value="username"
                  placeholder="Filter"
                  return-object
                  @change="applyFilter"
              ></v-autocomplete>
            </v-col>
            <v-col cols="auto">
              <v-cascader v-model="permission" :items="permissions" @input="updatePermissions()" v-on:clear="clearPermission()" :returnObject="true" clearable childrenKey="childPermissions" item-value="value" item-text="name" label="Servicearten" />
            </v-col>
          <v-col cols="auto" >
            <v-btn color="primary" @click="weekSwitch()">Woche</v-btn>
          </v-col>
          </v-row>
        </v-toolbar>
        <v-sheet height="75vh">
          <v-calendar
              ref="weekCalendar"
              v-model="focus"
              :event-color="getEventColor"
              :events="events"
              :short-weekdays="false"
              :type="type"
              :weekdays="weekdays"
              color="primary"
              event-overlap-mode="column"
              start="1999-06-07"
              @change="updateRange"
              @click:date="viewDay"
              @click:more="viewDay"

              @click:event="showEvent"
              @mousedown:event="startDrag"
              @mousedown:time="startTime"
              @mouseleave.native="cancelDrag"
              @mousemove:time="mouseMove"
              @mouseup:time="endDrag"
          >
            <template v-slot:event="{ event, timed, eventSummary }">
              <div class="v-event-draggable">
                <component :is="{ render: eventSummary }"></component>
              </div>
              <div
                  v-if="timed"
                  class="v-event-drag-bottom"
                  @mousedown.stop="extendBottom(event)"
              ></div>
            </template>
          </v-calendar>
          <v-menu
              v-model="selectedOpen"
              :activator="selectedElement"
              :close-on-content-click="false"
              offset-x
          >
            <v-card
                flat
                min-width="350px"
            >
              <v-toolbar
                  :color="selectedEvent.color"
                  dark
              >
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="openEditDialog()">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="deleteAppointment(selectedEvent.id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-text-field v-if="selectedEvent.dayOfWeek" :value="weekday.filter(day => day.name === selectedEvent.dayOfWeek)[0].ger + ' von ' + selectedEvent.start.substr(11, 5) + ' Uhr bis ' + selectedEvent.end.substr(11, 5) + ' Uhr'" disabled
                              prepend-icon="mdi-calendar-arrow-right"
                              readonly></v-text-field>
              </v-card-text>
              <v-card-title>Versicherungsberechtigungen</v-card-title>
              <div v-if="creatorsMap[selectedEvent.name]">
                <v-card class="ma-2" outlined color="primary"  v-if="creatorsMap[selectedEvent.name].advocardBusinessPermissions.length > 0">
                  <v-card-title>Advocard Business Berechtigungen</v-card-title>
                  <v-row class="mb-2 mx-2">
                    <v-col v-for="perm in creatorsMap[selectedEvent.name].advocardBusinessPermissions" v-bind:key="perm">
                      <v-chip>{{ perm }}</v-chip>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card class="ma-2" outlined color="primary" v-if="creatorsMap[selectedEvent.name].advocardInternationalPermissions.length > 0">
                  <v-card-title>Advocard International Berechtigungen</v-card-title>
                  <v-row class="mb-2 mx-2">
                    <v-col v-for="perm in creatorsMap[selectedEvent.name].advocardInternationalPermissions" v-bind:key="perm">
                      <v-chip>{{ perm }}</v-chip>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card class="ma-2" outlined color="primary"  v-if="creatorsMap[selectedEvent.name].advocardPrivatePermissions.length > 0">
                  <v-card-title>Advocard Private Berechtigungen</v-card-title>
                  <v-row class="mb-2 mx-2">
                    <v-col v-for="perm in creatorsMap[selectedEvent.name].advocardPrivatePermissions" v-bind:key="perm">
                      <v-chip>{{ perm }}</v-chip>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card class="ma-2" outlined color="primary"  v-if="creatorsMap[selectedEvent.name].aragPermissions.length > 0">
                  <v-card-title>ARAG Berechtigungen</v-card-title>
                  <v-row class="mb-2 mx-2">
                    <v-col v-for="perm in creatorsMap[selectedEvent.name].aragPermissions" v-bind:key="perm">
                      <v-chip>{{ perm }}</v-chip>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card class="ma-2" outlined color="primary"  v-if="creatorsMap[selectedEvent.name].rolandPermissions.length > 0">
                  <v-card-title>Roland Berechtigungen</v-card-title>
                  <v-row class="mb-2 mx-2">
                    <v-col v-for="perm in creatorsMap[selectedEvent.name].rolandPermissions" v-bind:key="perm">
                      <v-chip>{{ perm }}</v-chip>
                    </v-col>
                  </v-row>
                </v-card>

              </div>
              <v-card-actions>
                <v-btn
                    color="secondary"
                    text
                    @click="selectedOpen = false"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            color="primary"
            style="margin-right: 15px"
            @click="addFullDayAppointmentDialog = true"
        >
          <v-icon>mdi-phone</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- Name plan edit -->
    <v-dialog
        v-model="nameEditDialog"
        flat
        width="20vw"
    >
      <v-card>
        <v-card-title>
          Termin bearbeiten
        </v-card-title>
        <v-card-text>
          <v-autocomplete
              v-model="creator"
              :items="creators"
              :label="inputLables['creator']"
              :placeholder="inputLables['creatorSearch']"
              :search-input.sync="searchCreator"
              clearable
              color="primary"
              hide-no-data
              hide-selected
              item-text="username"
              item-value="username"
              return-object
          ></v-autocomplete>
          <v-menu
              v-if="selectedEvent.allDay === false"
              ref="menu1"
              v-model="clockMenu1"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="clockFrom"
              max-width="290px"
              min-width="290px"
              offset-y
              transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="clockFrom"
                  v-bind="attrs"
                  v-on="on"
                  label="Von"
                  prepend-icon="mdi-clock"
                  readonly
              ></v-text-field>
            </template>
            <v-time-picker
                v-if="clockMenu1"
                v-model="clockFrom"
                format="24hr"
                full-width
                @click:minute="$refs.menu1.save(clockFrom)"
            ></v-time-picker>
          </v-menu>
          <v-menu
              v-if="selectedEvent.allDay === false"
              ref="menu2"
              v-model="clockMenu2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="clockTo"
              max-width="290px"
              min-width="290px"
              offset-y
              transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="clockTo"
                  v-bind="attrs"
                  v-on="on"
                  label="Bis"
                  prepend-icon="mdi-clock"
                  readonly
              ></v-text-field>
            </template>
            <v-time-picker
                v-if="clockMenu2"
                v-model="clockTo"
                format="24hr"
                full-width
                @click:minute="$refs.menu2.save(clockTo)"
            ></v-time-picker>
          </v-menu>
          <!-- TODO add colors -->
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="error" text @click="nameEditDialog = false">
            Cancel
          </v-btn>
          <v-btn color="success" text @click="changeName(selectedEvent)">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Callback dialog -->
    <v-dialog
        v-model="addFullDayAppointmentDialog"
        color="grey lighten-4"
        flat
        width="20vw"
    >
      <v-card>
        <v-card-title>
          Hotline hinzufügen
        </v-card-title>
        <v-card-text>
          <v-autocomplete
              v-model="selectedDay"
              :items="days"
              :label="inputLables['day']"
              :placeholder="inputLables['daySearch']"
          >
          </v-autocomplete>
          <v-autocomplete
              v-model="creator"
              :items="creators"
              :label="inputLables['creator']"
              :placeholder="inputLables['creatorSearch']"
              :search-input.sync="searchCreator"
              color="primary"
              hide-no-data
              hide-selected
              item-text="username"
              item-value="username"
              return-object
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="error" text @click="addFullDayAppointmentDialog = false">
            Cancel
          </v-btn>
          <v-btn :disabled="selectedDay === '' || creator === ''" color="success"
                 text @click="absentSave()">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {getTemplateAppointmentsURL, getLawyersURL, getTemplateAppointmentURL, requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";
import {HistoryEntity, HistoryHandler} from "@/utils/historyHandler";
import VCascader from "@/components/VCascader";

export default {
  name: "Calender",
  components: { VCascader },
  data: () => ({
    type: 'week',
    filter: undefined,
    focus: '',
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    weekday: [
      {name: "MONDAY", date: "1999-06-07", ger: 'Montag'},
      {name: "TUESDAY", date: "1999-06-08", ger: 'Dienstag'},
      {name: "WEDNESDAY", date: "1999-06-09", ger: 'Mittwoch'},
      {name: "THURSDAY", date: "1999-06-10", ger: 'Donnerstag'},
      {name: "FRIDAY", date: "1999-06-11", ger: 'Freitag'},
      {name: "SATURDAY", date: "1999-06-12", ger: 'Samstag'},
      {name: "SUNDAY", date: "1999-06-13", ger: 'Sonntag'},
    ],
    events: [],
    clockFrom: "",
    clockMenu1: "",
    clockMenu2: "",
    dateMenu1: "",
    dateMenu2: "",
    dateMenu3: "",
    dateMenu4: "",
    dateFrom: "",
    dateFrom1: "",
    dateTo: "",
    dateTo1: "",
    clockTo: "",
// open event details dialog
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,

    //dialog bools
    nameEditDialog: false,
    addFullDayAppointmentDialog: false,

    // abselt dialog
    selectedDay: "",
    days: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'],

    //History handler
    historyHandler: new HistoryHandler(),
    beforeHistoryHandler: {},

    // getAllLawyers
    searchCreator: null,
    creators: [],
    creatorsMap: {},
    isLoadingCreator: false,
    creator: undefined,

    filteredByLawyers: false,
    filteredByPermissions: false,
    permission: null,
    //TODO build this same as AdminPanel to only have one point to edit
    permissions: [
      { name: "Advocard Private", value: "advocardPrivatePermissions", childPermissions: [ { name: "Telefonberatung", value: "advocardPrivatePermissions/Telefonberatung" }, { name: "Dokumentencheck", value: "advocardPrivatePermissions/Dokumentencheck" }, { name: "Mediation", value: "advocardPrivatePermissions/Mediation" }, { name: "VermittlungMediation", value: "advocardPrivatePermissions/VermittlungMediation" } ] },
      { name: "Advocard Business", value: "advocardBusinessPermissions", childPermissions: [ { name: "Telefonberatung", value: "advocardBusinessPermissions/Telefonberatung" } ] },
      { name: "Advocard International", value: "advocardInternationalPermissions", childPermissions: [ { name: "Telefonberatung", value: "advocardInternationalPermissions/Telefonberatung" }, { name: "Dokumentencheck", value: "advocardInternationalPermissions/Dokumentencheck" }, { name: "Mediation", value: "advocardInternationalPermissions/Mediation" }, { name: "VermittlungMediation", value: "advocardInternationalPermissions/VermittlungMediation" } ] },
      { name: "Arag", value: "aragPermissions", childPermissions: [
          { name: "Dokumentenprüfung", value: "aragPermissions/Dokumentenpruefung" },
          { name: "OnlineBeratung", value: "aragPermissions/OnlineBeratung" },
          { name: "Telefonberatung", value: "aragPermissions/Telefonberatung" },
          { name: "Vertragscheck", value: "aragPermissions/Vertragscheck" },
          { name: "Erstberatung Versicherungsprüfung", value: "aragPermissions/ErstberatungVersicherungspruefung" },
          { name: "Erstberatung Versicherungsprüfung Vermittlung", value: "aragPermissions/ErstberatungVersicherungspruefungVermittlung" },
          { name: "Manager Rechtsschutz", value: "aragPermissions/ManagerRechtsschutz" },
          { name: "Strafrechtshotline", value: "aragPermissions/Strafrechtshotline" },
          { name: "Telefonberatung247", value: "aragPermissions/Telefonberatung247" },
        ] },
      { name: "Roland", value: "rolandPermissions", childPermissions: [ { name: "Telefonberatung", value: "rolandPermissions/Telefonberatung" } ] },
    ],
  }),
  methods: {
    clearLawyer(){
      this.filteredByLawyers = false;
      if (!this.filteredByPermissions){
        this.events = this.unfilteredEvents;
      }
      if (this.filteredByPermissions){
        this.events = this.unfilteredEvents;
        this.updatePermissions()
      }
    },
    clearPermission(){
      this.filteredByPermissions = false;
      if (!this.filteredByLawyers){
        this.events = this.unfilteredEvents;
      }
      if (this.filteredByLawyers){
        this.events = this.unfilteredEvents;
        this.applyFilter()
      }
    },
    closeDialog(){
      this.$emit("closeDialog")
    },

    lawyerArrayToMap(arr){
      // console.log(arr)
      arr.forEach(lawyer => {
        const username = lawyer.username
        let permissions = {
          advocardBusinessPermissions: [],
          advocardInternationalPermissions: [],
          advocardPrivatePermissions: [],
          aragPermissions: [],
          rolandPermissions: []
        };
        //  console.log(lawyer, username)

        Object.keys(lawyer.advocardBusinessPermissions).forEach((key) => {
          if ( lawyer.advocardBusinessPermissions[key] === true){
            permissions.advocardBusinessPermissions.push(key)
          }
        }) ;
        Object.keys(lawyer.advocardInternationalPermissions).forEach((key) => {
          if ( lawyer.advocardInternationalPermissions[key] === true){
            permissions.advocardInternationalPermissions.push(key)
          }
        });
        Object.keys(lawyer.advocardPrivatePermissions).forEach((key) => {
          if ( lawyer.advocardPrivatePermissions[key] === true){
            permissions.advocardPrivatePermissions.push(key)
          }
        });
        Object.keys(lawyer.aragPermissions).forEach((key) => {
          if ( lawyer.aragPermissions[key] === true){
            permissions.aragPermissions.push(key)
          }
        });
        Object.keys(lawyer.rolandPermissions).forEach((key) => {
          if ( lawyer.rolandPermissions[key] === true){
            permissions.rolandPermissions.push(key)
          }
        })

        this.creatorsMap[username] = permissions
      })
    },
    updatePermissions(){
      if (this.permission){
        let value = this.permission.split("/")
        if (value[1]){
          if(this.filteredByLawyers && !this.filteredByPermissions){
            this.filteredByPermissions = true;
            this.events = this.events.filter(event => {
              if (this.creatorsMap[event.name]){
                if (this.creatorsMap[event.name][value[0]])
                  return this.creatorsMap[event.name][value[0]].filter(perm => {
                    return perm === value[1]
                  }).length > 0
              } else {
                return false;
              }
            })
          }  else if (this.filteredByPermissions && this.filteredByLawyers){
            this.events = this.unfilteredEvents.filter(event => {
              if (this.creatorsMap[event.name]){
                if (this.creatorsMap[event.name][value[0]])
                  return this.creatorsMap[event.name][value[0]].filter(perm => {
                    return perm === value[1]
                  }).length > 0
              } else {
                return false;
              }
            })
            this.events = this.events.filter(event => event.name === this.filter.username)
          }else {
            this.filteredByPermissions = true;
            this.events = this.unfilteredEvents.filter(event => {
              if (this.creatorsMap[event.name]){
                if (this.creatorsMap[event.name][value[0]])
                  return this.creatorsMap[event.name][value[0]].filter(perm => {
                    return perm === value[1]
                  }).length > 0
              } else {
                return false;
              }
            })
          }
        }
      }
    },
    applyFilter() {
      if (this.filter) {
        if(this.filteredByPermissions && !this.filteredByLawyers){
          this.filteredByLawyers = true;
          this.events = this.events.filter(event => event.name === this.filter.username)
        } else if (this.filteredByPermissions && this.filteredByLawyers){
          let value = this.permission.split("/")
          this.events = this.unfilteredEvents.filter(event => {
            if (this.creatorsMap[event.name]){
              if (this.creatorsMap[event.name][value[0]])
                return this.creatorsMap[event.name][value[0]].filter(perm => {
                  return perm === value[1]
                }).length > 0
            } else {
              return false;
            }
          })
          this.events = this.events.filter(event => event.name === this.filter.username)
        }
        else {
          this.filteredByLawyers = true;
          this.events = this.unfilteredEvents.filter(event => event.name === this.filter.username)
        }
      }
    },
    //get events
    async getEvents() {
      //  if Session isValid
      await this.getAllCreators();
      await sessionHandler();
      await this.$http
          .get(
              getTemplateAppointmentsURL()
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {

              this.unfilteredEvents = response.data
              if (this.unfilteredEvents.length === 0) {
                showAlert(i18n.t('warning.empty'), "warning");
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-gE4", "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
    },

    viewDay({date}) {
      this.focus = date
      this.type = 'day'
    },
    async weekSwitch() {
      this.type = 'week'
      await this.wait(25)
      this.hideDates()
    },
    hideDates() {
      for (let j = 1; j <= 7; j++) {
        try {
          this.$refs.weekCalendar.$children[0].$el.children[0].children[j].children[0].style.display = "none"
        } catch (e) {
          //not found
        }
      }
      this.$refs.weekCalendar.$children[0].$children.forEach((button) => {
        if (this.weekday.filter(wd => { return button.$el.children[0].innerHTML.includes(wd.ger) }).length === 0){
          const date = button.$el.children[0].innerHTML > 9 ? '1999-06-' + button.$el.children[0].innerHTML : '1999-06-0' + button.$el.children[0].innerHTML
            button.$el.children[0].innerHTML = this.weekday[this.weekday.findIndex(weekday => weekday.date === date)].ger
        }

        button.$el.classList.remove("v-btn--fab", "v-btn--round")
        button.$el.style.marginTop = "10px"
      })
    },
    async updateRange() {
      this.hideDates()
      this.unfilteredEvents = []
      this.events = []
      await this.getEvents()
      if (this.unfilteredEvents.length !== 0) {
        this.unfilteredEvents.forEach((event) => {
          event.start = this.weekday[this.weekday.findIndex(weekday => weekday.name === event.dayOfWeek)].date + " " + event.start.substr(0, 5)
          event.end = this.weekday[this.weekday.findIndex(weekday => weekday.name === event.dayOfWeek)].date + " " + event.end.substr(0, 5)
          if (event.name !== 'Zu benennen') {
            //let color = {color: "success", timed: true};
            event.color = "success";
          }
          if (event.allDay === true) {
            event.end = event.end.substr(0, 10)
            event.start = event.start.substr(0, 10)
            event.allDay = true
          }
        })
      }
      this.events = this.unfilteredEvents
      this.applyFilter()
      this.updatePermissions()
    },

  //get color
    getEventColor(event) {
      return event.color
    },

    //Time conv
    roundTime(time, down = true) {
      const roundTo = 60 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    getDateString(dateToFormat) {
      Number.prototype.cleanForDate = function () {
        switch (true){
          case (this === 0):
            return "00"
          case (this < 10 && this !== 0):
            return "0" + this
          default:
            return this.toString()

        }
      }
      const month = new Date(dateToFormat).getMonth() + 1
      const date = new Date(dateToFormat).getFullYear() + "-" + month.cleanForDate() + "-" + new Date(dateToFormat).getDate().cleanForDate()
      const time = new Date(dateToFormat).getHours().cleanForDate() + ":" + new Date(dateToFormat).getMinutes().cleanForDate()
      return date + " " + time
    },

    // open event details dialog
    showEvent({nativeEvent, event}) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => this.selectedOpen = true, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },

    //Drag & drop week
    startDrag({event, timed}) {
      if (event && timed) {
        this.beforeHistoryHandler.start = event.start
        this.beforeHistoryHandler.end = event.end
        this.beforeHistoryHandler.name = event.name
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    },
    async startTime(tms) {
      Date.prototype.addHours = function (hour) {
        this.setTime(this.getTime() + (hour * 60 * 60 * 1000));
        return this;
      }
      const mouse = this.toTime(tms)
      if (this.dragEvent && this.dragTime === null) {
        const start = new Date(this.dragEvent.start)
        this.dragTime = mouse - start
      } else {
        this.createStart = this.roundTime(mouse)
        this.createEvent = {
          start: new Date(this.createStart).getHours() + ":" + new Date(this.createStart).getMinutes(),
          end: new Date(this.createStart).addHours(1).getHours() + ":" + new Date(this.createStart).getMinutes(),
        }

        await this.createAppointment(this.weekday[new Date(this.createStart).getDay()].name, this.createEvent.start, this.createEvent.end, "Zu benennen", "hotline", false)
        this.createEvent.start = this.weekday[this.weekday.findIndex(weekday => weekday.name === this.createEvent.dayOfWeek)].date + " " + this.createEvent.start
        this.createEvent.end = this.weekday[this.weekday.findIndex(weekday => weekday.name === this.createEvent.dayOfWeek)].date + " " + this.createEvent.end

        let color = {color: "error", name: 'Zu benennen', timed: true,};
        this.createEvent = {...this.createEvent, ...color};
        this.events.push(this.createEvent)
      }
    },
    extendBottom(event) {
      this.createEvent = event
      this.beforeHistoryHandler.start = event.start
      this.beforeHistoryHandler.end = event.end
      this.beforeHistoryHandler.name = event.name
      this.createStart = new Date(event.start).getTime()
      this.extendOriginal = event.end

      this.updateAppointment(event.dayOfWeek, event.start, event.end, event.name, event.id, event.appointmentType, event.allDay)
    },
    mouseMove(tms) {
      Date.prototype.addHours = function (hour) {
        this.setTime(this.getTime() + (hour * 60 * 60 * 1000));
        return this;
      }
      const mouse = this.toTime(tms)
      if (this.dragEvent && this.dragTime !== null) {
        const start = new Date(this.dragEvent.start)
        const end = new Date(this.dragEvent.end)
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.dragEvent.dayOfWeek = this.weekday[this.weekday.findIndex(weekday => weekday.date === new Date(newStart).toISOString().substr(0, 10))].name
        this.dragEvent.start = this.getDateString(newStart)
        this.dragEvent.end = this.getDateString(newEnd)
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)

        this.createEvent.dayOfWeek = this.weekday[this.weekday.findIndex(weekday => weekday.date === new Date(min).toISOString().substr(0, 10))].name
        this.createEvent.start = this.getDateString(min)
        this.createEvent.end = this.getDateString(max)
      }
    },
    endDrag() {
      if (this.dragEvent && this.dragTime !== null) {
        this.updateAppointment(this.dragEvent.dayOfWeek, this.dragEvent.start, this.dragEvent.end, this.dragEvent.name, this.dragEvent.id, this.dragEvent.appointmentType, this.dragEvent.allDay)
      } else if (this.createEvent && this.createStart !== null) {
        if(this.createEvent.id){
          this.updateAppointment(this.createEvent.dayOfWeek, this.createEvent.start, this.createEvent.end, this.createEvent.name, this.createEvent.id, this.createEvent.appointmentType, this.createEvent.allDay)

        }
         }
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }
      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },

    // add Management Plan
    async createAppointment(day, startTime, endTime, name, appointmentType, allDay) {
      Date.prototype.addHours = function (hour) {
        this.setTime(this.getTime() + (hour * 60 * 60 * 1000));
        return this;
      }
      const formData = {
        "allDay": allDay,
        "start": startTime,
        "end": endTime,
        "dayOfWeek": day,
        "name": name,
        "appointmentType": appointmentType,
      };
      await sessionHandler();

      await this.$http
          .post(
              getTemplateAppointmentURL(),
              formData
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.createEvent = response.data
              this.beforeHistoryHandler.start = response.data.start
              this.beforeHistoryHandler.end = response.data.end
              this.beforeHistoryHandler.name = response.data.name
              let historyEntity = new HistoryEntity(getTemplateAppointmentURL(), "create", response.data)
              this.historyHandler.add(historyEntity)
              showAlert(i18n.t('success.form'), "success");
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-cA3", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-cA4", "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
    },
    async updateAppointment(dayOfWeek, startDate, endDate, name, id, appointmentType, allDay) {
      //TODO ask to update appointments?
      const formData = {
        "allDay": allDay,
        "start": startDate.substr(11, 5),
        "end": endDate.substr(11, 5),
        "name": name,
        "dayOfWeek": dayOfWeek,
        "appointmentType": appointmentType,
      };
      await sessionHandler();

      await this.$http
          .put(
              getTemplateAppointmentURL() + "/" + id,
              formData
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              let historyEntity = new HistoryEntity(getTemplateAppointmentURL(), "update", response.data, this.beforeHistoryHandler)
              this.historyHandler.add(historyEntity)

              showAlert(i18n.t('success.form'), "success");
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-uA1", "error");
            }
          })
          .catch((error) => {
            if (!error.response) {
              showAlert(i18n.t('error.api.undefined') + "Calendar-uA2", "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
    },
    async deleteAppointment(id) {
      await sessionHandler();

      await this.$http
          .delete(
              getTemplateAppointmentURL() + "/" + id
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.selectedOpen = false
              const removeIndex = this.events.map(function (item) {
                return item.id;
              }).indexOf(id);

              let historyEntity = new HistoryEntity(getTemplateAppointmentURL(), "delete", response.data, this.events[removeIndex])
              this.historyHandler.add(historyEntity)
              // TODO request instead
              this.events.splice(removeIndex, 1);
              showAlert(i18n.t('success.form'), "success");
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-C1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-C1", "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
    },

    //dialog hanlder
    openEditDialog() {
      this.nameEditDialog = true
      this.clockFrom = this.selectedEvent.start.substr(11, 5)
      this.clockTo = this.selectedEvent.end.substr(11, 5)
      this.dateFrom1 = this.selectedEvent.start.substr(0, 10)
      this.dateTo1 = this.selectedEvent.end.substr(0, 10)

    },
    changeName(event) {
      if (event.allDay === true) {
        if (this.creator !== undefined) {
          this.updateAppointment(event.dayOfWeek, event.start + " 12:00", event.end + " 12:00", this.creator.username, event.id, event.appointmentType, event.allDay)
          let foundIndex = this.events.findIndex(appointment => event.id === appointment.id)
          if (foundIndex !== -1) {
            this.events[foundIndex].name = this.creator.username
            this.events[foundIndex].start = this.dateFrom1 + " " + this.clockFrom
            this.events[foundIndex].end = this.dateTo1 + " " + this.clockTo
          }
          this.creator = undefined
          this.nameEditDialog = false
        }
      } else {
        if (this.creator !== undefined) {
          this.updateAppointment(event.dayOfWeek,  this.dateFrom1 + " " + this.clockFrom, this.dateTo1 + " " +this.clockTo, this.creator.username, event.id, event.appointmentType, event.allDay)
          let foundIndex = this.events.findIndex(appointment => event.id === appointment.id)
          if (foundIndex !== -1) {
            this.events[foundIndex].name = this.creator.username
            this.events[foundIndex].start = this.dateFrom1 + " " + this.clockFrom
            this.events[foundIndex].end = this.dateTo1 + " " + this.clockTo
            this.events[foundIndex].color = "accent"
          }
          this.creator = undefined
          this.nameEditDialog = false
        } else {
          this.updateAppointment(event.dayOfWeek, this.dateFrom1 + " " + this.clockFrom, this.dateTo1 + " " +this.clockTo, event.name, event.id, event.appointmentType, event.allDay)
          let foundIndex = this.events.findIndex(appointment => event.id === appointment.id)
          if (foundIndex !== -1) {
            this.events[foundIndex].start = this.dateFrom1 + " " + this.clockFrom
            this.events[foundIndex].end = this.dateTo1 + " " + this.clockTo
          }
          this.creator = undefined
          this.nameEditDialog = false
        }
      }
    },

    // get all lawyers
    async getAllCreators() {
      //  if Session isValid
      await sessionHandler();

      this.$http
          .get(
              getLawyersURL()
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.creators = response.data
              this.lawyerArrayToMap(response.data);
              if (this.creators.length === 0) {
                showAlert(i18n.t('info.noEntries'), "info");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-gAC1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-gAC2", "error");
            }
          })
          .finally(() => {
            this.isLoadingCreator = false;
          })
    },

    // wait
    wait(ms) {
      const start = new Date().getTime();
      let end = start;
      while (end < start + ms) {
        end = new Date().getTime();
      }
    },


    // absent add
    async absentSave() {
      await this.createAppointment(this.selectedDay, "12:00", "12:00", this.creator.username, 'callback', true)
      const event = {
        "allDay": true,
        "start": this.weekday[this.weekday.findIndex(weekday => weekday.name === this.selectedDay)].date,
        "end": this.weekday[this.weekday.findIndex(weekday => weekday.name === this.selectedDay)].date,
        "name": this.creator.username,
        "appointmentType": 'callback',
        "color": "primary",
        "id": this.createEvent.id
      };
      this.events.push(event)
      this.addFullDayAppointmentDialog = false
    }
  },

  mounted() {
    this.$refs.weekCalendar.checkChange()
  },

  computed: {
    inputLables() {
      return JSON.parse(JSON.stringify(this.$t('export.admin.inputField')));
    },
  },
  watch: {
    searchCreator() {
      // Items have already been loaded
      if (this.creators.length > 0) return

      // Items have already been requested
      if (this.isLoadingCreator) return

      this.isLoadingCreator = true

      // Lazily load input items
      this.getAllCreators();

    }
  },
}
</script>

<style lang="scss" scoped>
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>
